document.addEventListener("turbolinks:load", () => {
  $("[data-toggle='tooltip']").tooltip();

  const defaultOptions = {
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    locale: {
      format: "DD/MM/YYYY"
    }
  };

  const defaultRangeName = 'Last 30 Days';

  function callback(start, end) {
    $(this.element).val(`${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`);
  }

  $('.daterange').each(function() {
    let options = {}

    if($(this).data('isBlankDaterange')) {
      Object.assign(options, defaultOptions, { autoUpdateInput: false });
    } else {
      Object.assign(options, defaultOptions);

      if(!$(this).val()) {
        options.startDate = defaultOptions.ranges[defaultRangeName][0];
        options.endDate = defaultOptions.ranges[defaultRangeName][1];
      }
    }

    $(this).daterangepicker(options, callback);
  });
});
