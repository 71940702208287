require("@rails/ujs").start();
require("turbolinks").start();

require("jquery");
require("moment");
require("bootstrap");
require("bootstrap-daterangepicker");
require("modal");
require("turbolinks-load");

import "../stylesheets/application.scss"
