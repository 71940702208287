let rejectButtons = 'button[data-target="#rejectOrderModal"]';

$(document).on("click", rejectButtons, function () {
  let orderId = $(this)[0].getAttribute('data-id');
  let orderGuid = $(this)[0].getAttribute('data-guid');
  let form = $('.modal-body form');
  let form_action_url = form.attr('action');

  form_action_url = form_action_url.replace("order_id", orderId).replace("order_guid", orderGuid);

  form.attr('action', form_action_url);
});
